@use "../config/" as *;
.paywall-page {
    .header-wrap,
    .footer-wrap,
    .waf-row {
        @extend %d-none;
    }
    .paywall-active {
        display: block;
    }
    .main-wrap {
        @extend %p-t-zero;
    }
}
.waf-user-section {
    @extend %m-zero;
    @extend %p-x-half;
    @extend %p-y-zero;
    @extend %w-100;
    &::after {
        content: "";
        z-index: var(--zindex-overlay);
        background: url('/static-assets/images/cssimages/login-bg.jpg?v=#{$image-version}') no-repeat;
        background-size: cover;
        background-attachment: fixed;
        inset: 0;
        position: fixed;
        @extend %c-black-bg-3;
        @extend %d-block;
    }
}
.waf-subscribe {
    @extend %d-none;
    // display: block;
    // For Horizantal layout designs
    .plan-wrapper {
        .card {
            &-wrapper {
                padding-block: var(--full-space);
            }
            &-content {
                font-size: 1.2rem;
                + .card-content {
                    margin-top: var(--three-fourth-space);
                }
            }
            &-desc {
                padding-left: var(--full-space);
                .text {
                    padding-left: var(--half-space);
                    &:not(:last-child) {
                        margin-bottom: var(--half-space);
                    }
                    &::marker {
                        content: "\e805";
                        font: 1.6rem/1 $font-icon;
                        @extend %c-orange-10;
                    }
                }
            }
            &-action {
                margin-top: var(--full-space);
            }
            &-value {
                position: unset;
                align-items: flex-start;
                gap: .6rem;
                .number2 {
                    font-size: 1.2rem;
                    @extend %c-orange-10;
                }
            }
            &-title-wrap {
                margin-bottom: var(--full-space);
            }
            &-title {
                margin-bottom: 0;
            }
            &-sub-title {
                @extend %c-white-8;
            }
        }
    }
    .form-body-wrapper {
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        margin: var(--full-space) auto;
        @include linear-gradient(104.13deg, hsla(var(--hsl-c-pure-white)/0.24) -16.79%, hsla(var(--hsl-c-pure-white)/ 0.06) 38.23%, hsla(var(--hsl-c-pure-white)/ 0.06) 67.48%, hsla(var(--hsl-c-pure-white)/ 0.24) 116.93%);
        @include border(1, c-white, 2);
        @extend %p-x-full;
        @extend %p-y-two-space;
        @extend %full-radius;
    }
    .head {
        height: 9rem;
        @extend %m-t-full;
        @extend %flex-sb-c;
    }
    .head-logo {
        width: 5.5rem;
        height: 6.5rem;
    }
    .back-btn {
        .btn-text {
            @extend %font-14-pr;
            @extend %c-white-10;
            &:before {
                @extend %p-l-half;
                @include icon('\e800');
            }
        }
    }
    .btn {
        &-link,
        &-sub,
        &-site {
            @extend %capitalize;
        }
        &-link {
            @extend %font-16-pb;
            @extend %c-orange-10;
        }
        &-sub {
            @extend .btn-fill;
        }
        &-site {
            @extend %text-center;
        }
    }
    .form {
        &-note {
            @extend %m-t-two-space;
            @extend %c-grey-10;
            @extend %text-center;
            .text {
                @extend %font-10-pr;
            }
        }
        &-title {
            @extend %uppercase;
            @extend %m-t-zero;
            @extend %m-b-two-space;
            @extend %text-center;
            @extend %c-white-10;
            @extend %font-24-sb;
        }
        &-action {
            @extend %p-t-two-space;
            @extend %gap-full;
            @extend %flex-column;
        }
        &-desc {
            @extend %c-white-10;
            @extend %text-center;
            @extend %font-10-pr;
        }
        &-logo {
            width: 6rem;
            height: 6rem;
            @extend %m-b-one-n-half;
            @extend %flex-c-c;
            @extend %m-x-auto;
            .image {
                @extend %w-100;
                @extend %h-100;
            }
        }
    }
    .card {
        &-list {
            @extend %c-white-10;
            @extend %gap-full;
            @extend %flex-column;
        }
        &-item {
            flex-shrink: 0;
            @extend %w-100;
            @extend %relative;
        }
        &-title {
            width: 75%;
            @extend %m-b-full;
            @extend %m-t-zero;
            @extend %font-18-pm;
            @extend %capitalize;
        }
        &-wrapper {
            border: .1rem solid hsla(var(--hsl-c-pure-white)/0.01);
            @extend %half-radius;
            @extend %flex-column;
            @extend %p-y-two-space;
            @extend %p-x-full;
            @extend %c-white-bg-1;
        }
        &-desc {
            list-style: disc;
            @extend %m-b-full;
            @extend %p-l-one-n-half;
            .text {
                @extend %font-12-pr;
            }
        }
        &-value {
            @extend %flex-n-c;
            @include position(var(--two-space), var(--full-space), null, null);
            .number2 {
                @extend %font-14-pr;
            }
        }
        &-recommend {
            @extend %relative;
            @extend %c-orange-10;
            @extend %m-b-half;
            @extend %capitalize;
            &::before {
                content: '';
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                background: url('/static-assets/images/svg/star-icon.svg?v=#{$image-version}') no-repeat;
                background-size: cover;
            }
            .text {
                @extend %p-l-two-space;
                @extend %font-16-pb;
            }
        }
        &-radio {
            opacity: 0;
            cursor: pointer;
            @extend %w-100;
            @extend %h-100;
            @include position(0, null, null, 0);
            &:checked {
                ~ .card-wrapper {
                    border: .1rem solid hsla(var(--hsl-c-pure-white)/0.2);
                    box-shadow: 0 0 .8rem 0 hsla(var(--hsl-c-pure-white)/0.25);
                    @extend %c-white-bg-3;
                }
            }
        }
        &-action {
            margin-top: var(--one-n-half-space);
            .sub-text {
                cursor: pointer;
                @extend %p-y-half;
                @extend %p-x-full;
                @extend %rounded-radius;
                @extend %flex-c-c;
                @extend %c-white-bg-10;
                @extend %w-100;
                @extend %font-12-pr;
                @extend %c-blue-10;
            }
            .cancel-text {
                @extend %flex-c-c;
                @extend %capitalize;
                @extend %font-14-pb;
                @extend %c-orange-10;
            }
        }
    }
    .accordion-section {
        @extend %p-t-three-space;
        .text {
            margin-bottom: var(--half-space);
            // padding-left: var(--one-n-half-space);
            a {
                font-family: $font-pb;
            }
        }
        .accordion-list {
            list-style: disc;
            padding-left: var(--one-n-half-space);
        }
    }
    .control-label {
        @extend %c-white-6;
        @extend %font-12-pr;
        @extend %d-none;
    }
    input[type="date"] {
        color-scheme: dark;
    }
    .payment-wrapper-cancel {
        .form-title,
        .form-desc {
            @extend %m-b-two-space;
        }
        .card-list {
            @extend %m-b-zero;
        }
    }
    .payment-reject,
    .payment-accept {
        .form-title {
            @extend %m-b-two-space;
        }
    }
}
.plan-wrapper {
    .sub-title {
        @extend %m-y-zero;
        @extend %font-14-pb;
    }
    .card-value {
        .number1 {
            @extend %font-24-pr;
        }
    }
}
.payment-wrapper,
.payment-cards {
    .promo-code {
        @extend %relative;
        .correct {
            &::after {
                content: '\e805';
                font-size: 2.5rem;
                font-family: $font-icon;
                @extend %c-orange-10;
                @extend %position-t-r;
            }
        }
    }
    .promo-apply {
        cursor: pointer;
        @extend %m-t-full;
        @extend %p-l-full;
        @extend %capitalize;
        @extend %c-orange-10;
        @extend %font-16-pb;
    }
    .card-title {
        width: 100%;
    }
    .card-value {
        position: unset;
        .number1 {
            order: 3;
        }
        .number2 {
            @extend %font-14-pb;
            @extend %c-orange-10;
            @extend %m-x-half;
        }
        .number3 {
            order: -1;
            text-decoration: line-through .2rem hsla(var(--hsl-c-pure-white)/ 0.6);
        }
        .number3,
        .number1 {
            @extend %font-24-pr;
        }
    }
}
.payment-wrapper,
.payment-wrapper-cancel,
.payment-cards {
    .form-group {
        @extend %m-b-two-space;
    }
    .sub-title {
        @extend %m-y-zero;
        @extend %font-12-pr;
    }
    .card {
        &-list {
            gap: var(--one-n-half-space);
            @extend %m-b-two-space;
            .btn-link {
                cursor: pointer;
            }
        }
        &-title {
            @extend %m-b-zero;
        }
        &-wrapper {
            border: .1rem solid hsla(var(--hsl-c-pure-white)/0.2);
            box-shadow: 0 0 .8rem 0 hsla(var(--hsl-c-pure-white)/0.25);
            @extend %p-full;
            @extend %c-white-bg-2;
        }
        &-value {
            top: var(--full-space);
            .number1 {
                @extend %font-20-pr;
            }
        }
    }
}
.remove-plan,
.payment-reject {
    .btn-sub {
        text-transform: none;
    }
}
.payment-wrapper-cancel {
    .card-wrapper {
        display: grid;
        align-items: center;
        column-gap: var(--half-space);
        grid-template-areas: 'a c'
        'b c';
        justify-content: space-between;
    }
    .card-title {
        width: 100%;
        grid-area: a;
    }
    .card-value {
        position: unset;
        align-items: flex-end;
        flex-direction: column;
        grid-area: c;
    }
    .current-plan {
        @extend %font-12-pb;
        @extend %capitalize;
        @extend %c-orange-10;
    }
    .sub-title {
        width: 70%;
        margin-bottom: 0;
        grid-area: b;
    }
}
.payment-cards {
    .payment {
        &-portal {
            gap: var(--full-space);
            @extend %flex-column;
        }
        &-item {
            cursor: pointer;
            padding-inline: var(--one-n-half-space);
            border: .1rem solid hsla(var(--hsl-c-white)/ 0.3);
            @extend %relative;
            @extend %p-y-full;
            @extend %half-radius;
            @extend %flex-n-c;
            @extend %c-white-bg-2;
            &:after {
                content: '\e801';
                font-size: 2.4rem;
                font-family: $font-icon;
                right: var(--half-space);
                @extend %c-white-10;
                @extend %position-v-center;
            }
        }
        &-text {
            @extend %p-l-half;
            @extend %c-white-10;
            @extend %font-16-pb;
        }
    }
    .logo {
        width: 2rem;
        height: 2rem;
    }
}
.modal {
    &.subscribe-modal {
        @extend %d-none;
    }
}
.subscribe-modal {
    @extend %flex-c-c;
    &.active {
        height: var(--window-inner-height);
        @extend %flex;
    }
    &::before {
        content: '';
        z-index: var(--zindex-subscribe-modal);
        // @extend %c-blue-bg-10;
        background: url('/static-assets/images/cssimages/paywall-subscribe-bg-mob.png?v=#{$image-version}') center / cover no-repeat;
        @extend %w-100;
        @extend %h-100;
        @include position-combo('inset');
    }
    .modal-wrapper {
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        @extend %w-90;
        @extend %text-center;
        @extend %c-white-bg-1;
        @extend %p-x-full;
        @extend %p-y-two-space;
        @extend %full-radius;
        @include border(1, c-white, 2);
    }
    .form {
        &-title {
            @extend %uppercase;
            @extend %m-y-full;
            @extend %c-white-10;
            @extend %font-24-sb;
        }
        &-desc {
            @extend %m-b-three-space;
            @extend %c-white-10;
            @extend %font-10-pr;
        }
        &-action {
            @extend %gap-full;
            @extend %flex-column;
        }
        &-logo {
            width: 6rem;
            height: 7rem;
            @extend %m-x-auto;
        }
    }
    .btn {
        &-sub,
        &-cancle {
            @extend %capitalize;
        }
        &-sub {
            @extend .btn-fill;
        }
        &-cancle {
            @extend %font-16-pb;
            @extend %c-orange-10;
        }
    }
}
input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
:is(.error) .expiration input {
    border-bottom: transparent;
}
.expiration {
    border-bottom: .1rem solid var(--c-white);
    @extend %flex;
    @extend %p-x-full;
    @extend %p-y-half;
    &.active {
        border-bottom: .1rem solid var(--c-orange);
    }
    input {
        width: auto;
        border-bottom: 0;
        padding: 0;
        &:active,
        &:focus,
        &:focus-visible {
            border-color: transparent;
        }
    }
    span {
        font-size: 1.6rem;
        margin-right: 1rem;
        @extend %c-white-5;
    }
}
.global-msg,
.error {
    .message,
    .error-message {
        @extend %c-white-6;
        @extend %p-t-half;
    }
}
.error {
    input {
        border-bottom: .1rem solid hsl(var(--hsl-c-error)/6);
    }
    .expiration {
        border-bottom: .1rem solid hsl(var(--hsl-c-error)/6);
    }
}
.iPhone {
    .expiration {
        input {
            width: 3rem;
        }
        span {
            margin-left: 1rem;
        }
    }
}
.waf-paywall-modal {
    padding-block: 4rem;
    z-index: var(--zindex-modal);
    overflow-y: auto;
    @include position-combo(inset, null, null, fixed);
    &.waf-user-section {
        @extend %p-x-full;
        .custom-form {
            @extend %text-left;
        }
        .card-action .cancel-text {
            @extend %d-block;
        }
        .form {
            &-logo {
                width: 4.3rem;
                height: 4.3rem;
                .image {
                    object-fit: contain
                }
            }
            &-action {
                @extend %text-center;
            }
        }
        .remove-plan, .error-wrapper {
            .form-action {
                gap: var(--two-space)
            }
        }
        .payment-wrapper-cancel {
            .btn-cancel .btn-text {
                @extend %c-orange-10;
            }
        }
    }
    .btn-sub {
        .btn-text {
            @extend %c-blue-10;
        }
        &:hover .btn-text {
            @extend %c-white-10;
        }
    }
    .payment-wrapper-cancel {
        .form {
            &-container {
                @extend %m-t-zero;
            }
            &-action {
                @extend %p-t-zero;
            }
        }
    }
    .cancel-text {
        cursor: pointer;
    }
}
@media screen and (min-width:$tablet-min-width) {
    .waf-subscribe {
        .plan-wrapper {
            max-width: 100%;
            padding: 5rem;
            .card {
                &-list {
                    align-items: stretch;
                }
                &-item {
                    order: 1;
                    &.recommended {
                        order: 2;
                    }
                    &.ppv {
                        order: 3;
                    }
                }
                &-wrapper {
                    height: 100%;
                }
                &-value {
                    margin-top: auto;
                }
            }
            // For Horizontal styles
            .horizontal {
                flex-direction: column;
                flex-wrap: nowrap;
                .card {
                    &-item {
                        width: 100%;
                    }
                    &-wrapper {
                        padding-block: var(--one-n-half-space);
                        display: grid;
                        justify-content: space-between;
                        gap: var(--full-space);
                        grid-template-columns: 30% auto;
                    }
                    &-title-wrap {
                        margin-bottom: 0;
                    }
                    &-title {
                        margin-bottom: 0;
                    }
                    &-sub-title {
                        font-size: 1.6rem;
                    }
                    &-desc .text {
                        font-size: 1.4rem;
                    }
                    &-action {
                        margin-top: 0;
                        display: flex;
                        @include flex-config(flex, null, flex-end, flex-start);
                        .sub-text {
                            width: fit-content;
                        }
                    }
                }
                .sub-title {
                    margin-bottom: 0;
                    color: hsl(var(--hsl-c-white) / .6);
                    font-family: $font-pr;
                    grid-area: d;
                }
            }
        }
        .head-logo {
            width: 7rem;
            height: 8.5rem;
        }
        .back-btn {
            .btn-text {
                @include font(16);
            }
        }
        .form-body-wrapper {
            padding-block: calc(var(--full-space)*3);
            padding-inline: calc(var(--two-space)*2);
        }
        .form {
            &-title {
                margin-bottom: calc(var(--full-space)*3);
                @include font(32);
            }
            &-action {
                margin-inline: auto;
                padding-block: calc(var(--full-space)*3);
            }
            &-note {
                width: 50%;
                margin-inline: auto;
                // margin-top: 0;
                .text {
                    @include font(12);
                }
            }
            &-desc {
                @include font(16);
            }
        }
        .sub-title {
            margin-bottom: var(--half-space);
        }
        .card {
            &-list {
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                @include card-count(2, var(--full-space));
            }
            &-item {
                flex-shrink: 1;
            }
            &-value {
                position: unset;
            }
            &-title {
                width: 100%;
                @include font(20);
            }
            &-desc {
                min-height: 5.5rem;
                .text {
                    @include font(12);
                }
            }
            &-action {
                .sub-text {
                    padding-inline: var(--one-n-half-space);
                }
                .cancel-text {
                    justify-content: flex-start;
                }
            }
        }
        .btn {
            &-sub,
            &-cancel {
                height: 4rem;
            }
            &-sub {
                .btn-text {
                    font-weight: 700;
                    font-size: 1.6rem;
                }
            }
        }
        .control-label {
            @include flex-config(flex);
        }
        &:not(.uww-plus) .plan-wrapper .card-list {
            justify-content: center;
        }
    }
    .payment-wrapper,
    .payment-success,
    .remove-plan,
    .payment-wrapper-cancel,
    .payment-accept,
    .payment-reject,
    .payment-cards {
        max-width: 62rem;
    }
    .payment-wrapper-cancel .card-wrapper {
        padding: var(--one-n-half-space);
    }
    .remove-plan .form-title {
        max-width: 33rem;
        margin-inline: auto;
    }
    .plan-wrapper {
        max-width: 100rem;
        .form-action {
            width: 50%;
        }
        .card-value {
            .number1 {
                @include font(32);
            }
        }
    }
    .payment-wrapper,
    .payment-wrapper-cancel,
    .payment-cards {
        padding: calc(var(--full-space)*5);
        .card {
            &-list {
                flex-direction: column;
                .card-item {
                    width: 100%;
                }
            }
            &-value {
                .number1 {
                    @include font(32);
                }
            }
            &-title {
                font-family: $font-psb;
            }
        }
        .form-action {
            padding-bottom: 0;
        }
    }
    .payment-wrapper-cancel {
        .current-plan {
            font-size: 1.6rem;
        }
        .sub-title {
            width: 80%;
        }
    }
    .payment-wrapper,
    .payment-cards {
        .promo-code {
            .correct {
                &::after {
                    top: 2.6rem;
                }
            }
        }
        .card-title {
            width: 75%;
        }
        .card-value {
            .number3,
            .number1 {
                @include font(32);
            }
        }
    }
    .modal {
        &.subscribe-modal {
            padding: 0;
            @include flex-config(null, row, center, null);
        }
    }
    .subscribe-modal {
        &::before {
            content: '';
            background: url('/static-assets/images/cssimages/paywall-subscribe-bg-desk.png?v=#{$image-version}') center / cover no-repeat;
        }
        .modal-wrapper {
            width: 40%;
            padding-inline: calc(var(--full-space)*5);
            padding-block: calc(var(--full-space)*3);
        }
        .form {
            &-title {
                @include font(32);
            }
            &-desc {
                @include font(14);
            }
            &-logo {
                width: 7rem;
                height: 8rem;
            }
        }
        .btn {
            &-sub,
            &-cancle {
                height: 4rem;
            }
            &-sub {
                .btn-text {
                    font-weight: 700;
                    font-size: 1.6rem;
                }
            }
        }
    }
    .waf-paywall-modal {
        padding-block: 8rem;
        &.waf-user-section {
            .plan-wrapper {
                max-width: unset;
            }
            .form {
                &-body-wrapper {
                    padding: calc(var(--full-space) * 5);
                }
                &-title {
                    margin-bottom: 0;
                }
                &-desc {
                    margin-top: var(--two-space);
                }
                &-action {
                    padding-bottom: 0;
                }
            }
        }
        .payment-wrapper-cancel .form-container {
            margin-top: var(--full-space);
        }
    }
}
@media screen and (min-width:$desktop-min-width) {
    .waf-subscribe .card-list {
        @include card-count(3, var(--full-space));
    }
}
@media screen and (min-width: $large-desktop-min-width) {
    .waf-subscribe .plan-wrapper .horizontal {
        .card-wrapper {
            grid-template-columns: 18% 1fr 18% auto;
        }
        .sub-title {
            margin-bottom: 0;
            grid-area: d;
        }
    }
}